import React from 'react';
import {
  generatePath,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { PATH, SLUG } from './constants/path';
import { Customer } from './models/customer';
import { AppLoader } from './components/loader/app-loader';
import { logout, getCurrentCustomerDetails, getConfig } from './api/public';
import { PrivatePage } from './private-page';
import { PublicPage } from './public-page';
import { AppContext, AppContextValue } from './app-context';
import { Config } from './models/config';
import { handleError } from './utils/handle-error';
import { Terms } from './components/terms/terms';

const LoginPage = React.lazy(() => import('./pages/login-page/login-page'));
const PasswordResetRequestPage = React.lazy(
  () =>
    import(
      './pages/password-reset/password-reset-request-page/password-reset-request-page'
    )
);
const PasswordResetPage = React.lazy(
  () => import('./pages/password-reset/password-reset-page/password-reset-page')
);
const NewsPage = React.lazy(() => import('./pages/news-page/news-page'));
const EditArticlePage = React.lazy(
  () => import('./pages/news-page/edit-article-page/edit-article-page')
);
const InvoicesOuterPage = React.lazy(
  () => import('./pages/invoices-page/invoices-outer-page')
);
const InvoicesPage = React.lazy(
  () => import('./pages/invoices-page/invoices-page/invoices-page')
);
const ProformasPage = React.lazy(
  () => import('./pages/invoices-page/proformas-page/proformas-page')
);
const BasketPage = React.lazy(() => import('./pages/basket-page/basket-page'));
const OrderCreatedPage = React.lazy(
  () => import('./pages/basket-page/order-created-page/order-created-page')
);
const OrdersPage = React.lazy(() => import('./pages/orders-page/orders-page'));
const OrderPage = React.lazy(
  () => import('./pages/orders-page/order-page/order-page')
);
const SearchResultsPage = React.lazy(
  () => import('./pages/search-results-page/search-results-page')
);
const ItemPage = React.lazy(() => import('./pages/item-page/item-page'));
const DownloadsPage = React.lazy(
  () => import('./pages/downloads-page/downloads-page')
);
const PriceListPage = React.lazy(
  () => import('./pages/downloads-page/price-list-page/price-list-page')
);
const StockPage = React.lazy(
  () => import('./pages/downloads-page/stock-page/stock-page')
);
const QuotationsPage = React.lazy(
  () => import('./pages/quotations-page/quotations-page')
);
const QuotationPage = React.lazy(
  () => import('./pages/quotations-page/quotation-page/quotation-page')
);
const QuotationOrderCreatedPage = React.lazy(
  () =>
    import(
      './pages/quotations-page/quotation-order-created-page/quotation-order-created-page'
    )
);
const AdminPage = React.lazy(() => import('./pages/admin-page/admin-page'));
const CustomerListPage = React.lazy(
  () => import('./pages/admin-page/customer-list-page/customer-list-page')
);
const NotificationsPage = React.lazy(
  () => import('./pages/admin-page/notifications-page/notifications-page')
);
const NotificationPage = React.lazy(
  () =>
    import(
      './pages/admin-page/notifications-page/notification-page/notification-page'
    )
);
const ConfigPage = React.lazy(
  () => import('./pages/admin-page/config-page/config-page')
);
const CreateNotificationsPage = React.lazy(
  () =>
    import(
      './pages/admin-page/notifications-page/create-notification-page/create-notification-page'
    )
);
const ProfilePage = React.lazy(
  () => import('./pages/profile-page/profile-page')
);
const GeneralInfoPage = React.lazy(
  () => import('./pages/profile-page/general-info-page/general-info-page')
);
const AddressListPage = React.lazy(
  () => import('./pages/profile-page/address-list-page/address-list-page')
);
const NotFoundPage = React.lazy(
  () => import('./pages/not-found-page/not-found-page')
);

const App: React.FC = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [customerDetails, setCustomerDetails] = React.useState<Customer | null>(
    null
  );
  const [config, setConfig] = React.useState<Config | null>(null);

  const handleLogOut = React.useCallback(() => {
    return logout().then(() => {
      setCustomerDetails(null);
      navigate(generatePath(PATH.LOGIN_PAGE));
    });
  }, [navigate]);

  const handleReloadCustomerDetails = (
    onResult: (customer: Customer | null) => void
  ) =>
    getCurrentCustomerDetails()
      .then((customer) => {
        onResult(customer);
        setCustomerDetails(customer);
      })
      .catch(handleError);

  const handleReloadConfig = (onResult: (config: Config | null) => void) =>
    getConfig()
      .then((config) => {
        onResult(config);
        setConfig(config);
      })
      .catch(handleError);

  const appContextValue = React.useMemo<AppContextValue>(
    () => ({
      customerDetails,
      config,
      reloadCustomerDetails: handleReloadCustomerDetails,
      reloadConfig: handleReloadConfig,
    }),
    [customerDetails, config]
  );

  React.useEffect(() => {
    Promise.all([
      getCurrentCustomerDetails().then(setCustomerDetails),
      getConfig().then(setConfig),
    ]).finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return <AppLoader />;
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <Routes>
        <Route
          index={true}
          element={
            <Navigate
              to={
                customerDetails
                  ? generatePath(PATH.NEWS_PAGE)
                  : generatePath(PATH.LOGIN_PAGE)
              }
            />
          }
        />
        <Route element={<PublicPage config={config} />}>
          <Route path={SLUG.LOGIN} element={<LoginPage />} />
          <Route path={SLUG.PASSWORD_RESET}>
            <Route index={true} element={<PasswordResetRequestPage />} />
            <Route path={SLUG.ID} element={<PasswordResetPage />} />
          </Route>
        </Route>
        <Route
          element={
            customerDetails ? (
              <PrivatePage
                customerDetails={customerDetails}
                config={config}
                onLogOut={handleLogOut}
              />
            ) : (
              <Navigate to={generatePath(PATH.LOGIN_PAGE)} />
            )
          }
        >
          <Route
            index={true}
            element={
              <Navigate to={generatePath(PATH.NEWS_PAGE)} replace={true} />
            }
          />
          <Route path={SLUG.NEWS}>
            <Route index={true} element={<NewsPage />} />
            <Route path={SLUG.NEWS_CREATE} element={<EditArticlePage />} />
            <Route path={SLUG.ID}>
              <Route path={SLUG.NEWS_EDIT} element={<EditArticlePage />} />
            </Route>
          </Route>
          <Route path={SLUG.INVOICES} element={<InvoicesOuterPage />}>
            <Route index={true} element={<InvoicesPage />} />
            <Route path={SLUG.PROFORMAS} element={<ProformasPage />} />
          </Route>
          <Route path={SLUG.BASKET}>
            <Route index={true} element={<BasketPage />} />
            <Route
              path={SLUG.BASKET_ORDER_CREATED}
              element={<OrderCreatedPage />}
            />
          </Route>
          <Route path={SLUG.ORDERS}>
            <Route index={true} element={<OrdersPage />} />
            <Route path={SLUG.ID} element={<OrderPage />} />
          </Route>
          <Route path={SLUG.SEARCH_RESULTS} element={<SearchResultsPage />} />
          <Route path={SLUG.ITEM}>
            <Route path={SLUG.ID} element={<ItemPage />} />
          </Route>
          <Route path={SLUG.DOWNLOADS} element={<DownloadsPage />}>
            <Route
              index={true}
              element={
                <Navigate
                  to={generatePath(PATH.PRICE_LIST_PAGE)}
                  replace={true}
                />
              }
            />
            <Route path={SLUG.PRICE_LIST} element={<PriceListPage />} />
            <Route path={SLUG.STOCK} element={<StockPage />} />
          </Route>
          <Route path={SLUG.QUOTATIONS}>
            <Route index={true} element={<QuotationsPage />} />
            <Route path={SLUG.ID}>
              <Route index={true} element={<QuotationPage />} />
              <Route
                path={SLUG.QUOTATION_ORDER_CREATED}
                element={<QuotationOrderCreatedPage />}
              />
            </Route>
          </Route>
          <Route path={SLUG.ADMIN} element={<AdminPage />}>
            <Route
              index={true}
              element={
                <Navigate
                  to={generatePath(PATH.CUSTOMER_LIST_PAGE)}
                  replace={true}
                />
              }
            />
            <Route path={SLUG.CUSTOMER_LIST} element={<CustomerListPage />} />
            <Route path={SLUG.NOTIFICATIONS}>
              <Route index={true} element={<NotificationsPage />} />
              <Route path={SLUG.ID} element={<NotificationPage />} />
              <Route
                path={SLUG.NOTIFICATIONS_CREATE}
                element={<CreateNotificationsPage />}
              />
            </Route>
            <Route path={SLUG.CONFIG} element={<ConfigPage />} />
          </Route>
          <Route path={SLUG.PROFILE} element={<ProfilePage />}>
            <Route
              index={true}
              element={
                <Navigate
                  to={generatePath(PATH.PROFILE_GENERAL_INFO)}
                  replace={true}
                />
              }
            />
            <Route
              path={SLUG.PROFILE_GENERAL_INFO}
              element={<GeneralInfoPage />}
            />
            <Route
              path={SLUG.PROFILE_ADDRESSES}
              element={<AddressListPage />}
            />
          </Route>
        </Route>
        <Route
          element={
            customerDetails ? (
              <PrivatePage
                customerDetails={customerDetails}
                config={config}
                onLogOut={handleLogOut}
              />
            ) : (
              <PublicPage config={config} />
            )
          }
        >
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <Terms config={config} />
    </AppContext.Provider>
  );
};

export default App;
